// export const API_ROOT = 'http://awg-api.local';
// export const API_ROOT = 'https://api.awg-rewards.dev4.stream9.net';
export const API_ROOT = 'https://api.rewards.atlantic-oase.com';

export const CHART_OPTIONS = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
            beginAtZero: true,
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                display: false,
            },
            border: {
                display: false,
            }
        },
        x: {
            grid: {
                display: false,
            },
            ticks: {
                color: '#fff',
            },
            border: {
                color: '#fff',
            }
        }
    },
    elements: {
        line: {
            tension: 0,
        },
        point: {
            radius: 0,
        }
    },
    plugins: {
        legend: {
            display: false,
        }
    },
}
